import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  flexCenter,
  Input,
  Loading,
  styled,
  Text,
} from "@100mslive/react-ui";

function CreateRoom() {
  const navigate = useNavigate();
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    name: "",
    mobile: "",
  });

  const handleInputChange = e => {
    setInputValues(prevValues => ({
      ...prevValues,
      [e.target.name]: e.target.value,
      [e.target.mobile]: e.target.value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const { name = "", mobile = "" } = inputValues;

    if (name.length === 0 || mobile.length === 0) {
      return;
    }
    const data = await createRoom({ username: name, mobile: mobile });
    navigate(`/meeting/${data?.room_id}/host`);
  };

  const createRoom = async payload => {
    try {
      setIsLoading(true);
      const response = await fetch(`${serverURL}quickCallTokens`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        setIsLoading(false);
        throw new Error("Request failed with status: " + response.msg);
      }

      const data = await response.json();

      if (!data || data === null) {
        setIsLoading(false);

        throw new Error("Data not found");
      }
      setIsLoading(false);
      return data.data;
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      throw error; // Rethrow the error to be caught in the calling function if needed
    }
  };

  return (
    <Container>
      <Flex justify="center" align="center" direction="column">
        <Form onSubmit={handleSubmit}>
          <Text variant="h4" css={{ marginBottom: "0.5rem" }}>
            Create a room
          </Text>
          <Text variant="sm" css={{ marginBottom: "0.5rem" }}>
            Enter your name and mobile number to create a room
          </Text>
          <Input
            required
            value={inputValues.name}
            onChange={handleInputChange}
            id="name"
            type="text"
            name="name"
            placeholder="Your name"
          />
          <Input
            required
            id="mobile_number"
            type="tel"
            pattern="[0-9]{10}"
            maxLength={10}
            minLength={10}
            name="mobile"
            placeholder="Mobile number"
            onChange={handleInputChange}
          />

          <Button
            type="submit"
            variant="primary"
            className="btn-primary"
            disabled={isLoading}
          >
            {isLoading && <Loading css={{ color: "$textSecondary" }} />}
            Create
          </Button>
        </Form>
      </Flex>
    </Container>
  );
}

const Container = styled("div", {
  width: "100%",
  height: "100%",
  ...flexCenter,
  flexDirection: "column",
});

const Form = styled("form", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "$4",
  mt: "$10",
  mb: "$10",
});
export default CreateRoom;
